import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { auth, db } from '../../config/firebase-config'; 
import { doc, deleteDoc } from 'firebase/firestore';
import { useFetchArticles } from "../../hooks/useFetchArticles";
import formatDate from "../../hooks/useGetDateFormat";
import Logo from '../../images/logo.PNG';
import { recomputeUniqueAuthors } from '../../utility/recomputeUniqueAuthors';
import "./styles.css";


function Variety() {
  const [displayCount, setDisplayCount] = useState(9);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { articles: varietyArticles, loading: varietyLoading, error: varietyError } = useFetchArticles("variety", displayCount);


  const handleSeeMore = () => {
    setDisplayCount(prevCount => prevCount + 9); // Increase by 9 to fetch 9 more articles
  };

  // Function to chunk the articles array into groups of 3
  const groupArticlesIntoRows = (articles, size) => {
    const row = [];
    for (let i = 0; i < articles.length; i += size) {
      row.push(articles.slice(i, i + size));
    }
    return row;
  };

  const renderAuthorLinks = (article) => {
    if (Array.isArray(article.authors)) {
      // Filter out empty strings
      const filteredAuthors = article.authors.filter(author => author.trim() !== "");
  
      return filteredAuthors.map((author, index) => (
        <React.Fragment key={index}>
          {index > 0 && (index === filteredAuthors.length - 1 ? ' and ' : ', ')}
          <Link to={`/author/${author}`} className="author-email-link">
            {author}
          </Link>
        </React.Fragment>
      ));
    } else {
      // For older articles with a single author
      return (
        <Link to={`/author/${article.author}`} className="author-email-link">
          {article.author}
        </Link>
      );
    }
  };
  
  

  // Chunk the articles to be displayed
  const articleRow = groupArticlesIntoRows(varietyArticles, 3);

  // Determine the number of rows to display based on displayCount
  const displayedRows = articleRow.slice(0, Math.ceil(displayCount / 3));

  const handleDeleteVarietyArticle = async (articleId) => {
    if (!window.confirm('Are you sure you want to delete this article?')) return;
    try {
      await deleteDoc(doc(db, 'variety-articles', articleId));
      await recomputeUniqueAuthors(); // Recompute unique authors after deletion

      // Refresh the articles or handle the UI update
    } catch (error) {
      console.error('Error deleting article:', error);
    }
  };


  return (
    <div className="variety-articles">
      
      <h1>Variety</h1>
      {varietyLoading && <p>Loading...</p>}
      {varietyError && <p>Error loading articles.</p>}

      {displayedRows.map((row, index) => (
        <React.Fragment key={index}>
          <div className="articles-row">
            {row.map((article) => (
              <div className="article-card-variety" key={article.id}>
                <div className="variety-image-container">
                <img
                  src={article.imageUrls[0] || Logo}
                  alt={article.title}
                  className="article-image-variety"
                />
                </div>
                <div className="article-contents">
                  <h2>
                    <Link
                      to={`/article/variety/${article.id}`}
                      className="title-link"
                    >
                      {article.title}
                    </Link>
                  </h2>
                  <p>
                    By {renderAuthorLinks(article)}&nbsp;&nbsp;
                    <span className="author-email">
                     | &nbsp;{article.email}                  
                    </span>
                  </p>
                  <p>
                    <span className="article-date">
                      {article.createdAt ? formatDate(article.createdAt) : 'Date unavailable'}
                    </span>
                  </p>
                  <div className="description">
                    <p>{article.description}</p>
                  </div>
                </div>
                {auth.currentUser && (
                  <button onClick={() => handleDeleteVarietyArticle(article.id)} className="delete-article-button">
                    Delete Article
                  </button>
                )}
              </div>
            ))}
          </div>
          {index < displayedRows.length - 1 && <hr />}{" "}
          {/* Only add <hr /> between rows, not after the last row */}
        </React.Fragment>
      ))}

      {varietyArticles && varietyArticles.length >= displayCount && (
        <div className="see-more-container">
          <button onClick={handleSeeMore} className="see-more-button">
            See More
          </button>
        </div>
      )}
    </div>
  );
}

export default Variety;
