import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { FaSearch } from 'react-icons/fa'; // Make sure you have react-icons installed
import './styles.css';
import useSearchArticles from '../../hooks/useSearchArticles';
import {  FaTimes } from 'react-icons/fa';

function SearchInput() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isInputVisible, setInputVisible] = useState(false);
  const history = useHistory();
  const [searchTermOnSubmit, setSearchTermOnSubmit] = useState(''); // State to hold search term on submit
  const { loading, error, results } = useSearchArticles(searchTermOnSubmit);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  

  const handleSubmit = (e) => {
    e.preventDefault(); // prevent page reload
    const trimmedSearchTerm = searchTerm.trim();
    setSearchTermOnSubmit(trimmedSearchTerm);
    setInputVisible(false); // Hide the search input after submission
    if (trimmedSearchTerm) {
      history.push(`/search-results?query=${encodeURIComponent(trimmedSearchTerm)}`);
    }
  };

  const handleIconClick = () => {
    setInputVisible(true);
    // Focus the input after a tick to ensure the input is visible
    setTimeout(() => {
      document.querySelector('.search-input').focus();
    }, 0);
  };

  const handleInputBlur = () => {
    // Hide the input when it loses focus and if it's empty
    if (!searchTerm.trim()) {
      setInputVisible(false);
    }
  };

  const handleCancelClick = () => {
    setInputVisible(false);
    setSearchTerm(''); // Clear the search term
  };
  
  return (
    <div className='container-search'>
      <FaSearch className="search-icon" onClick={handleIconClick} />
      {isInputVisible && (
        <form onSubmit={handleSubmit} className="search-form">
          <FaTimes className="cancel-icon" onClick={handleCancelClick} />
          <input
            className="search-input"
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search for articles..."
            onBlur={handleInputBlur}
            autoFocus // This will focus the input on render
          />
          <button type="submit">Search</button>
        </form>
      )}
    </div>
  );
}

export default SearchInput;
