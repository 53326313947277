import React, { useState } from "react";
import { auth } from "../../config/firebase-config";
import { useFetchParagraphs } from "../../hooks/useFetchParagraphs";
import { marked } from 'marked';
import { Link } from 'react-router-dom';
import "./styles.css";

function Advertising() {
  const { paragraphs, updateParagraph } = useFetchParagraphs("advertising");
  const currentUser = auth.currentUser;
  const isSignedIn = !!currentUser;
  const [editingId, setEditingId] = useState(null);
  const [draftTexts, setDraftTexts] = useState({}); // This is now an object

  const handleUpdateParagraphs = async (paragraphId) => {
    try {
      await updateParagraph(
        paragraphId,
        { text: draftTexts[paragraphId] }, // Update the specific field
        "advertising"
      );
      setEditingId(null); // Reset editing ID
      setDraftTexts(prevDrafts => ({ ...prevDrafts, [paragraphId]: '' })); // Clear the draft text
    } catch (err) {
      console.error("Error updating paragraph:", err);
    }
  };

  const handleEditClick = (paragraphId) => {
    setEditingId(paragraphId);
    setDraftTexts(prevDrafts => ({
      ...prevDrafts,
      [paragraphId]: paragraphs.find(p => p.id === paragraphId).text
    }));
  };

  return (
    <div className="container-advertising">
      <h1>Advertising</h1>
      <Link to="/contact">Contact Us</Link>
      {paragraphs &&
        paragraphs.map((paragraph) => (
          <div key={paragraph.id} className="advertising">
            {editingId === paragraph.id ? (
              <textarea
                value={draftTexts[paragraph.id] || ''}
                onChange={(e) => setDraftTexts({
                  ...draftTexts,
                  [paragraph.id]: e.target.value
                })}
              />
            ) : (
              <p dangerouslySetInnerHTML={{ __html: marked(paragraph.text) }} />
            )}
            {editingId === paragraph.id ? (
              <button onClick={() => handleUpdateParagraphs(paragraph.id)}>
                Submit
              </button>
            ) : (
              isSignedIn && (
                <button onClick={() => handleEditClick(paragraph.id)}>
                  Edit
                </button>
              )
            )}
          </div>
        ))}
    </div>
  );
}

export default Advertising;
