import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useFetchArticles } from "../../hooks/useFetchArticles";
import { auth } from "../../config/firebase-config";
import ArticleInput from "../../hooks/useAddArticle";
import useFetchFeaturedArticle from "../../hooks/useFetchFeaturedArticle";
import useFetchFeaturedArticle2 from "../../hooks/useFetchFeaturedArticle2";
import useFetchFeaturedArticle3 from "../../hooks/useFetchFeaturedArticle3";
import useSetFeaturedArticle from "../../hooks/useSetFeaturedArticle";
import useSetFeaturedArticle2 from "../../hooks/useSetFeaturedArticle2";
import useSetFeaturedArticle3 from "../../hooks/useSetFeaturedArticle3";
import formatDate from "../../hooks/useGetDateFormat";
import Logo from '../../images/logo.PNG';
import "./styles.css";

function Home() {
  const {
    articles: varietyArticles,
    loading: varietyLoading,
    error: varietyError,
  } = useFetchArticles("variety", 15);
  const {
    articles: newsArticles,
    loading: newsLoading,
    error: newsError,
  } = useFetchArticles("news", 15);
  const {
    articles: sportsArticles,
    loading: sportsLoading,
    error: sportsError,
  } = useFetchArticles("sports", 15);
  const {
    articles: opinionArticles,
    loading: opinionLoading,
    error: opinionError,
  } = useFetchArticles("opinion", 15);

  const currentUser = auth.currentUser;
  const isSignedIn = !!currentUser;

  const {
    featuredArticle,
    loading: featuredLoading,
    error: featuredError,
  } = useFetchFeaturedArticle();

  const {
    featuredArticle2,
    loading2: featuredLoading2,
    error2: featuredError2,
  } = useFetchFeaturedArticle2();

  const {
    featuredArticle3,
    loading3: featuredLoading3,
    error3: featuredError3,
  } = useFetchFeaturedArticle3();

  const [inputTitle, setInputTitle] = useState("");
  const [inputTitle2, setInputTitle2] = useState("");
  const [inputTitle3, setInputTitle3] = useState("");

  const {
    loading: settingFeaturedLoading,
    error: settingFeaturedError,
    success: settingFeaturedSuccess,
    setFeaturedArticle,
  } = useSetFeaturedArticle();

  const {
    loading2: settingFeaturedLoading2,
    error2: settingFeaturedError2,
    success2: settingFeaturedSuccess2,
    setFeaturedArticle2,
  } = useSetFeaturedArticle2();

  const {
    loading3: settingFeaturedLoading3,
    error3: settingFeaturedError3,
    success3: settingFeaturedSuccess3,
    setFeaturedArticle3,
  } = useSetFeaturedArticle3();

  const allArticles = React.useMemo(
    () => [
      ...newsArticles.map((article) => ({
        ...article,
        collectionName: "news-articles",
      })),
      ...varietyArticles.map((article) => ({
        ...article,
        collectionName: "variety-articles",
      })),
      ...sportsArticles.map((article) => ({
        ...article,
        collectionName: "sports-articles",
      })),
      ...opinionArticles.map((article) => ({
        ...article,
        collectionName: "opinion-articles",
      })),
    ],
    [newsArticles, varietyArticles, sportsArticles, opinionArticles]
  );

  function formatCollectionName(name) {
    const collectionNameMapping = {
      "news-articles": "news",
      "variety-articles": "variety",
      "sports-articles": "sports",
      "opinion-articles": "opinion",
    };

    return collectionNameMapping[name] || name; // If no mapping is found, return the original name
  }
  
  const renderAuthorLinks = (article) => {
    if (Array.isArray(article.authors)) {
      // Filter out empty strings
      const filteredAuthors = article.authors.filter(author => author.trim() !== "");
  
      return filteredAuthors.map((author, index) => (
        <React.Fragment key={index}>
          {index > 0 && (index === filteredAuthors.length - 1 ? ' and ' : ', ')}
          <Link to={`/author/${author}`} className="author-email-link">
            {author}
          </Link>
        </React.Fragment>
      ));
    } else {
      // For older articles with a single author
      return (
        <Link to={`/author/${article.author}`} className="author-email-link">
          {article.author}
        </Link>
      );
    }
  };

  return (
    <div className="container-home">
      {/* Allow admins to add articles */}
      <div className="article-input">
        {isSignedIn ? <ArticleInput /> : <p></p>}
      </div>

      {/* Allow admins to set the featured article */}
      {isSignedIn && (
        <div className="set-featured-article">
          <h2>Set Featured Article</h2>
          <input
            type="text"
            placeholder="Enter article title"
            value={inputTitle}
            onChange={(e) => setInputTitle(e.target.value)}
          />
          <button onClick={() => setFeaturedArticle(inputTitle, allArticles)}>
            Set as Featured
          </button>
        </div>
      )}

      {/* Allow admins to set the featured 2 article */}
      {isSignedIn && (
        <div className="set-featured-article">
          <h2>Set Featured Article 2</h2>
          <input
            type="text"
            placeholder="Enter article title"
            value={inputTitle2}
            onChange={(e) => setInputTitle2(e.target.value)}
          />
          <button onClick={() => setFeaturedArticle2(inputTitle2, allArticles)}>
            Set as Featured
          </button>
        </div>
      )}

      {/* Allow admins to set the featured 3 article */}
      {isSignedIn && (
        <div className="set-featured-article">
          <h2>Set Featured Article 3</h2>
          <input
            type="text"
            placeholder="Enter article title"
            value={inputTitle3}
            onChange={(e) => setInputTitle3(e.target.value)}
          />
          <button onClick={() => setFeaturedArticle3(inputTitle3, allArticles)}>
            Set as Featured
          </button>
        </div>
      )}
      <div className="featured-title-top">
        
        <h1></h1>
      </div>
      <div className="featured-and-other">
        <div className="featured-section">
          {settingFeaturedLoading && <p>Setting featured article...</p>}
          {settingFeaturedError && (
            <p>
              Error setting featured article: {settingFeaturedError.message}
            </p>
          )}
          {settingFeaturedSuccess && (
            <p>Article set as featured successfully!</p>
          )}

          {settingFeaturedLoading2 && <p>Setting featured article...</p>}
          {settingFeaturedError2 && (
            <p>
              Error setting featured article: {settingFeaturedError2.message}
            </p>
          )}
          {settingFeaturedSuccess2 && (
            <p>Article set as featured successfully!</p>
          )}

          {settingFeaturedLoading3 && <p>Setting featured article...</p>}
          {settingFeaturedError3 && (
            <p>
              Error setting featured article: {settingFeaturedError3.message}
            </p>
          )}
          {settingFeaturedSuccess3 && (
            <p>Article set as featured successfully!</p>
          )}

          {/* Display the Featured Article */}
          <div className="featured-article">
            {featuredLoading && <p>Loading...</p>}
            {featuredError && (
              <p>Error loading featured article: {featuredError.message}</p>
            )}
            {featuredArticle && (
              <div className="featured-content">
                <img
                  src={featuredArticle.imageUrls[0] || Logo}
                  alt={featuredArticle.title}
                  className="featured-image"
                />
                <div className="featured-text">
                  <h2 style={{ textAlign: "center" }}>
                    <Link
                      to={`/article/${formatCollectionName(
                        featuredArticle.collectionName
                      )}/${featuredArticle.id}`}
                      className="title-link"
                    >
                      {featuredArticle.title}
                    </Link>
                  </h2>
                  <p style={{ textAlign: "center" }}>
                    <div className="featured-author-and-email">
                      <div className="featured-author-link">
                        <div className="author-link">
                        By {renderAuthorLinks(featuredArticle)}&nbsp;
                        </div>
                      </div>
                      <div className="featured-email">
                        | {featuredArticle.email}
                      </div>
                    </div>
                    </p>
                    <p className="fetaured-date">
                      <span className="article-date">
                        {featuredArticle.createdAt
                          ? formatDate(featuredArticle.createdAt)
                          : "Date unavailable"}
                      </span>
                    </p>
                  <p className="fetaured-description">{featuredArticle.description}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="otherFeatured">
          <div className="featured2" id="article1">
            {/* Display the Featured Article 2 */}
            <div className="featured-article2">
              {featuredLoading2 && <p>Loading...</p>}
              {featuredError2 && (
                <p>Error loading featured article: {featuredError2.message}</p>
              )}
              {featuredArticle2 && (
                <div className="featured-content2">
                  <img
                    src={featuredArticle2.imageUrls[0] || Logo}
                    alt={featuredArticle2.title}
                    className="featured-image2"
                  />
                  <div className="featured-text2">
                    <h2>
                      <Link
                        to={`/article/${formatCollectionName(
                          featuredArticle2.collectionName
                        )}/${featuredArticle2.id}`}
                        className="title-link2"
                      >
                        {featuredArticle2.title}
                      </Link>
                    </h2>
                    <p>
                      <div className="featured-author-and-email2">
                        <div className="featured-author-link2">
                          By {renderAuthorLinks(featuredArticle2)}&nbsp;
                        </div>
                        <div className="featured-email2">
                          | {featuredArticle2.email}
                        </div>
                      </div>
                    </p>
                    <p style={{ textAlign: "center" }} className="featured-date">
                      <span className="article-date">
                        {featuredArticle2.createdAt
                          ? formatDate(featuredArticle2.createdAt)
                          : "Date unavailable"}
                      </span>
                    </p>
                    <p>{featuredArticle2.description}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          
          <div className="featured3" id="article3">
          <div className="featured-article3">
            {featuredLoading3 && <p>Loading...</p>}
            {featuredError3 && (
              <p>Error loading featured article: {featuredError3.message}</p>
            )}
            {featuredArticle3 && (
              <div className="featured-content3">
                <img
                  src={featuredArticle3.imageUrls[0] || Logo}
                  alt={featuredArticle3.title}
                  className="featured-image3"
                />
                <div className="featured-text3">
                  <h2>
                    <Link
                      to={`/article/${formatCollectionName(
                        featuredArticle3.collectionName
                      )}/${featuredArticle3.id}`}
                      className="title-link3"
                    >
                      {featuredArticle3.title}
                    </Link>
                  </h2>
                  <p>
                      <div className="featured-author-and-email3">
                        <div className="featured-author-link3">
                          By {renderAuthorLinks(featuredArticle3)}&nbsp;
                        </div>
                        <div className="featured-email3">
                          | {featuredArticle3.email}
                        </div>
                      </div>
                    </p>
                    <p style={{ textAlign: "center" }} className="featured-date">
                      <span className="article-date">
                        {featuredArticle3.createdAt
                          ? formatDate(featuredArticle3.createdAt)
                          : "Date unavailable"}
                      </span>
                    </p>
                  <p>{featuredArticle3.description}</p>
                </div>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>

      <div className="articles-section">
        <h1 class="section-title">News</h1>

        {newsLoading && <p>Loading...</p>}
        {newsError && <p>Error loading articles.</p>}
        <div className="articles-row">
          {newsArticles?.slice(0, 3).map((article) => (
            <div className="article-card" key={article.id}>
              <div className="article-container">
              <div className="image-container">
                <img
                  src={article.imageUrls[0] || Logo}
                  alt={article.title}
                  className="article-image"
                />
              </div>
              <div className="article-contents">
                <h2 className="articleTitle">
                  <Link
                    to={`/article/news/${article.id}`}
                    className="title-link"
                  >
                    {article.title}
                  </Link>
                </h2>
                <p>
                  By {renderAuthorLinks(article)}&nbsp;
                  | {article.email}
                </p>
                <p>
                  <span className="article-date">
                    {article.createdAt
                      ? formatDate(article.createdAt)
                      : "Date unavailable"}
                  </span>
                </p>
                <div className="description">
                  <p>{article.description}</p>
                </div>
              </div>
              </div>
            </div>
          ))}
        </div>
        <Link to="/news" className="more-button">
          More News
        </Link>
      </div>

      <div className="articles-section">
        <h1 className="section-title">Variety</h1>

        {varietyLoading && <p>Loading...</p>}
        {varietyError && <p>Error loading articles.</p>}
        <div className="articles-row">
          {varietyArticles?.slice(0, 3).map((article) => (
            <div className="article-card" key={article.id}>
              <div className="article-container">
              <div className="image-container">
                <img
                  src={article.imageUrls[0] || Logo}
                  alt={article.title}
                  className="article-image"
                />
              </div>
              <div className="article-contents">
                <h2 className="articleTitle">
                  <Link
                    to={`/article/variety/${article.id}`}
                    className="title-link"
                  >
                    {article.title}
                  </Link>
                </h2>
                <p>
                  By {renderAuthorLinks(article)}&nbsp;
                  | {article.email}
                </p>
                <p>
                  <span className="article-date">
                    {article.createdAt
                      ? formatDate(article.createdAt)
                      : "Date unavailable"}
                  </span>
                </p>
                <div className="description">
                  <p>{article.description}</p>
                </div>
              </div>
              </div>
            </div>
          ))}
        </div>
        <Link to="/variety" className="more-button">
          More Variety
        </Link>
      </div>

      <div className="articles-section">
        <h1 class="section-title">Sports</h1>

        {sportsLoading && <p>Loading...</p>}
        {sportsError && <p>Error loading articles.</p>}
        <div className="articles-row">
          {sportsArticles?.slice(0, 3).map((article) => (
            <div className="article-card" key={article.id}>
              <div className="article-container">
              <div className="image-container">
                <img
                  src={article.imageUrls[0] || Logo}
                  alt={article.title}
                  className="article-image"
                />
              </div>
              <div className="article-contents">
                <h2 className="articleTitle">
                  <Link
                    to={`/article/sports/${article.id}`}
                    className="title-link"
                  >
                    {article.title}
                  </Link>
                </h2>
                <p>
                  By {renderAuthorLinks(article)}&nbsp;
                  | {article.email}
                </p>
                <p>
                  <span className="article-date">
                    {article.createdAt
                      ? formatDate(article.createdAt)
                      : "Date unavailable"}
                  </span>
                </p>
                <div className="description">
                  <p>{article.description}</p>
                </div>
              </div>
              </div>
            </div>
          ))}
        </div>
        <Link to="/sports" className="more-button">
          More Sports
        </Link>
      </div>

      <div className="articles-section">
        <h1 class="section-title">Opinion</h1>

        {opinionLoading && <p>Loading...</p>}
        {opinionError && <p>Error loading articles.</p>}
        <div className="articles-row">
          {opinionArticles?.slice(0, 3).map((article) => (
            <div className="article-card" key={article.id}>
              <div className="article-container">
                <div className="image-container">
                  <img
                    src={article.imageUrls[0] || Logo}
                    alt={article.title}
                    className="article-image"
                  />
                </div>
                <div className="article-contents">
                  <h2 className="articleTitle">
                    <Link
                      to={`/article/opinion/${article.id}`}
                      className="title-link"
                    >
                      {article.title}
                    </Link>
                  </h2>
                  <p>
                    By {renderAuthorLinks(article)}&nbsp;
                    | {article.email}
                  </p>
                  <p>
                    <span className="article-date">
                      {article.createdAt
                        ? formatDate(article.createdAt)
                        : "Date unavailable"}
                    </span>
                  </p>
                  <div className="description">
                    <p>{article.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Link to="/opinion" className="more-button">
          More Opinion
        </Link>
      </div>
    </div>
  );
}

export default Home;
