import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { useHistory } from "react-router-dom";
import { signOut } from "firebase/auth";
import './styles.css';



function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [user, setUser] = useState(null); // <- Add this state variable to hold the user object
  let history = useHistory();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if(user) {
        setUser(user); // <- Set user when user is signed in
      } else {
        setUser(null); // <- Set to null when no user is signed in
      }
    });

    return () => unsubscribe(); // <- Clean up subscription on component unmount
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      // Optionally, you can redirect the user to a different page after logout
      history.push("/");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  if(user) {
    // If user is logged in, render this
    return (
      <div className="logged-in-container">
        <div className="logged-in-box">
          <p>You are logged in!</p>
          <button onClick={logout} className="logout-button">Logout</button>
        </div>
      </div>
    );
  }
  
  
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!resetEmail) {
        setShowForgotPassword(true);
        return;
    }
    try {
        await sendPasswordResetEmail(auth, resetEmail);
        alert('Password reset email has been sent to ' + resetEmail);
        setResetEmail('');
        setShowForgotPassword(false);
    } catch (error) {
        console.error("Error sending password reset email", error);
        setErrorMessage('Error sending password reset email. Please try again.');
    }
  };  

  const login = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      history.push("/"); // redirect to home or dashboard page on successful login
    } catch (error) {
      // Check the error code and set an appropriate error message
      if (error.code === "auth/invalid-login-credentials") {
        setErrorMessage("Incorrect email or password");
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error("Error code:", errorCode);
        console.error("Error message:", errorMessage);
      } else {
        setErrorMessage("An error occurred. Please try again");
      }
      console.error("Error signing in", error.code);
    }
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={login}>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <button type="submit">Login</button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {showForgotPassword && (
                <div className="reset-email-box">
                    <input 
                        type="email" 
                        placeholder="Enter your email" 
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                        required 
                    />
                </div>
            )}
        <a href="#" onClick={handleForgotPassword} className="forgot-password-link">Forgot Password?</a>
      </form>
    </div>
  );
}

export default Auth;
