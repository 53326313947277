import React from 'react';
import Logo from '../../images/logo.PNG';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from "react-router-dom";
import './styles.css';
import { size } from 'lodash';


function Footer() {
  return (
    <div className='footer'>
      <div className='footer-left'>
        <img src={Logo} className='logo' alt='Logo'/>
      </div>
      <div className='footer-middle'>
        <div className='social-links'>
          <a href='https://www.instagram.com/csbsjurecord/' target='_blank' rel='noopener noreferrer'>
            <InstagramIcon className="social-icon"/> 
          </a>
          <a href='https://www.twitter.com/csbsjurecord/' target='_blank' rel='noopener noreferrer'>
            <TwitterIcon className="social-icon"/>
          </a>
          <a href='https://www.facebook.com/TheRecordCSBSJU?mibextid=uzlsIk' target='_blank' rel='noopener noreferrer'>
            <FacebookIcon className="social-icon"/>
          </a>
          <a href='https://www.linkedin.com/company/the-recordcsbsju/about/' target='_blank' rel='noopener noreferrer'>
            <LinkedInIcon className="social-icon"/>
          </a>
        </div>
        <div className='pol-adv-copy'>
          <Link to="/policies" className='policies-link'>Policies</Link>
          <Link to="/contact" className='contact-link'>Contact Us</Link>
          <p> &copy; 2023 csbsjurecord.com</p>
          <p>Website by Rodney Erickson SJU '24</p>
        </div> 
      </div>
      <div className='footer-right'>
        <img src={Logo} className='logo' alt='Logo'/>
      </div>
    </div>
  )
}

export default Footer