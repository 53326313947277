import { useState } from 'react';
import { db } from '../config/firebase-config';
import { doc, setDoc } from 'firebase/firestore';

function useSetFeaturedArticle() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  async function setFeaturedArticle(articleTitle, allArticles) {
    const articleToFeature = allArticles.find(article => article.title === articleTitle);
    if (!articleToFeature) {
      setError(new Error("Article with the given title not found"));
      return;
    }

    const { collectionName, id: articleID } = articleToFeature;
    
    setLoading(true);
    setError(null);
    setSuccess(false);
        
    try {
      // Define the reference to the featured article document
      const featuredArticleRef = doc(db, 'featured-articles', 'featured-article');
      
      // Update the featured article document
      await setDoc(featuredArticleRef, {
        articleID: articleID,
        collectionName: collectionName
      });
      
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  return { loading, error, success, setFeaturedArticle };
}

export default useSetFeaturedArticle;
