import { useEffect, useState } from 'react';
import { fetchArticlesFromCategory } from '../api/index';

export const useFetchArticles = (category, articlesLimit) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchArticles = async () => {
      try {
        const newArticles = await fetchArticlesFromCategory(category, articlesLimit);
        setArticles(newArticles);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchArticles();
  }, [category, articlesLimit]);

  return { articles, loading, error };
};


