import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import axios from 'axios';

function useSearchArticles(searchTerm) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (!searchTerm) {
      setLoading(false);
      setResults([]);
      return;
    }

    const fetchData = async () => {
      try {
        let fetchedResults = [];

        // Convert searchTerm to lowercase for case-insensitive search
        const normalizedSearchTerm = searchTerm.toLowerCase();

        // List of collections
        const collections = ['sports-articles', 'news-articles', 'variety-articles', 'opinion-articles'];
        console.log(searchTerm);
        for (let page of collections) {
          const articlesCollection = collection(db, page);

          // Perform a case-insensitive search on the title field
          const titleQuery = query(articlesCollection,
            where('title-lowercase', '>=', normalizedSearchTerm),
            where('title-lowercase', '<=', normalizedSearchTerm + '\uf8ff')
          );
          const titleSnapshot = await getDocs(titleQuery);
          fetchedResults = [...fetchedResults, ...titleSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))];

          // Perform a case-insensitive search on the text field
          const textQuery = query(articlesCollection,
            where('text-lowercase', '>=', normalizedSearchTerm),
            where('text-lowercase', '<=', normalizedSearchTerm + '\uf8ff')
          );
          const textSnapshot = await getDocs(textQuery);
          fetchedResults = [...fetchedResults, ...textSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))];
        }
        console.log(fetchedResults);
        setResults(fetchedResults);
        setLoading(false);
      } catch (e) {
        console.error("Error fetching search results:", e);
        setError(e);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchTerm]);

  return { loading, error, results };
}

export default useSearchArticles;
