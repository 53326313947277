import React from 'react';
import './styles.css';

function StaffPerson({ user, isSignedIn, onDelete }) {

  const bioContent = user.bio.replace(/\n/g, '<br />');
  return (
    <div className="staff-person">
      {user.imageURL ? <img src={user.imageURL} alt={user.name} className="staff-image" /> : null}
      {isSignedIn && <button onClick={onDelete} className="delete-button">Delete</button>}
      <h2 className="staff-name">{user.name}</h2>
      <h3 className="staff-title">{user.title}</h3>
      <div className="staff-bio" dangerouslySetInnerHTML={{ __html: bioContent  }}></div>
    </div>
    
  );
}


export default StaffPerson