import React from 'react';
import Logo from '../../images/RecordLogo.png';
import './styles.css';

function NavbarMiddle() {
  // Get today's date using JavaScript's Date object
  const today = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return (
    <div className="logo-container">
        <img src={Logo} className='navbar-logo' alt='Record Logo'/>
        {/* Display the date text below the logo */}
        {/**  <div className="date-display">{today}</div> **/}
    </div>
  );
}

export default NavbarMiddle;

