import React, { useState } from "react";
import { auth } from "../../config/firebase-config";
import { useFetchParagraphs } from "../../hooks/useFetchParagraphs";
import { marked } from "marked";
import "./styles.css";

function Policies() {
  const { paragraphs, updateParagraph } = useFetchParagraphs("policies");
  const currentUser = auth.currentUser;
  const isSignedIn = !!currentUser;
  const [isEditing, setIsEditing] = useState(false);
  const [draftText, setDraftText] = useState({});

  const handleUpdateParagraphs = async (paragraphId, fieldName) => {
    try {
      await updateParagraph(
        paragraphId,
        { [fieldName]: draftText },
        "policies"
      );
    } catch (err) {
      console.error("Error updating paragraph:", err);
    } finally {
      setIsEditing(false);
      setDraftText("");
    }
  };

  return (
    <div className="container-policies">
      <div className="policies-top">
        <div className="header-policies">
          <h1>Policies</h1>
        </div>
        {/* ... Handling Loading and Error States ... */}

        {/* Display the first 5 paragraphs under Policies */}
        {paragraphs &&
          paragraphs
            .slice(0, 1)
            .map((paragraph) => (
              <ParagraphComponent
                key={paragraph.id}
                paragraph={paragraph}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                draftText={draftText}
                setDraftText={setDraftText}
                handleUpdateParagraphs={handleUpdateParagraphs}
                isSignedIn={isSignedIn}
              />
            ))}
      </div>

      <div className="policies-middle">
        <div className="header-policies">
          <h1>Terms of Use</h1>
        </div>
        
        {/* Display the rest of the paragraphs under Terms of Use */}
        {paragraphs &&
          paragraphs
            .slice(1, 2)
            .map((paragraph) => (
              <ParagraphComponent
                key={paragraph.id}
                paragraph={paragraph}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                draftText={draftText}
                setDraftText={setDraftText}
                handleUpdateParagraphs={handleUpdateParagraphs}
                isSignedIn={isSignedIn}
              />
            ))}
      </div>

      <div className="policies-list">
        {/* ... Handling Loading and Error States ... */}

        {/* Display the rest of the paragraphs as bullet points */}
        <ul>
          {paragraphs &&
            paragraphs
              .slice(2, 12)
              .map((paragraph) => (
                <ListItemComponent
                  key={paragraph.id}
                  listItem={paragraph}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  draftText={draftText}
                  setDraftText={setDraftText}
                  handleUpdateParagraphs={handleUpdateParagraphs}
                  isSignedIn={isSignedIn}
                />
              ))}
        </ul>
      </div>

      <div className="policies-bottom">
        {/* ... Handling Loading and Error States ... */}

        {/* Display the rest of the paragraphs under Terms of Use */}
        {paragraphs &&
          paragraphs
            .slice(12)
            .map((paragraph) => (
              <ParagraphComponent
                key={paragraph.id}
                paragraph={paragraph}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                draftText={draftText}
                setDraftText={setDraftText}
                handleUpdateParagraphs={handleUpdateParagraphs}
                isSignedIn={isSignedIn}
              />
            ))}
      </div>
    </div>
  );
}

// Create a new component to avoid duplication
function ParagraphComponent({
  paragraph,
  isEditing,
  setIsEditing,
  draftText,
  setDraftText,
  handleUpdateParagraphs,
  isSignedIn,
}) {
  const createMarkup = (markdownText) => {
    return { __html: marked(markdownText) };
  };
  return (
    <React.Fragment key={paragraph.id}>
      {isEditing === paragraph.id ? (
        <textarea
          value={draftText}
          onChange={(e) => setDraftText(e.target.value)}
        />
      ) : (
        <p dangerouslySetInnerHTML={createMarkup(paragraph.text)} />
      )}
      {isEditing === paragraph.id ? (
        <button onClick={() => handleUpdateParagraphs(paragraph.id, "text")}>
          Submit
        </button>
      ) : (
        isSignedIn && (
          <button
            onClick={() => {
              setIsEditing(paragraph.id);
              setDraftText(paragraph.text);
            }}
          >
            Edit
          </button>
        )
      )}
    </React.Fragment>
  );
}

function ListItemComponent({
  listItem,
  isEditing,
  setIsEditing,
  draftText,
  setDraftText,
  handleUpdateParagraphs,
  isSignedIn,
}) {
  return (
    <li key={listItem.id}>
      {isEditing === listItem.id ? (
        <textarea
          value={draftText}
          onChange={(e) => setDraftText(e.target.value)}
        />
      ) : (
        listItem.text
      )}
      {isEditing === listItem.id ? (
        <button onClick={() => handleUpdateParagraphs(listItem.id, "text")}>
          Submit
        </button>
      ) : (
        isSignedIn && (
          <button
            onClick={() => {
              setIsEditing(listItem.id);
              setDraftText(listItem.text);
            }}
          >
            Edit
          </button>
        )
      )}
    </li>
  );
}

export default Policies;
