import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';

const useFetchUniqueAuthors = () => {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const docRef = doc(db, 'unique-authors', 'tNxb2ODPMiYTS9tpnuQy');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setAuthors(docSnap.data().authors || []);
        } else {
          console.log("No such document in 'unique-authors'");
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAuthors();
  }, []);

  return { authors, loading, error };
};

export default useFetchUniqueAuthors;
