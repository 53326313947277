import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import useSearchArticles from "../../hooks/useSearchArticles";
import formatDate from "../../hooks/useGetDateFormat";
import Logo from "../../images/logo.PNG";
// import useSearchAuthor from "../../hooks/useSearchAuthor"
import "./styles.css";
import { useState } from "react";

function SearchResultsPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");
  const { loading, error, results: articles } = useSearchArticles(query);
  // const { loading, error, authorExists, author } = useSearchAuthor(query);
  const [displayCount, setDisplayCount] = useState(9); // Start with displaying 9 articles

  useEffect(() => {
    // This useEffect will re-run whenever `results` changes
    
  }, [articles]);

  // Function to chunk the articles into groups of three
  const groupArticlesIntoRows = (articles, size) => {
    const rows = [];
    for (let i = 0; i < articles.length; i += size) {
      rows.push(articles.slice(i, i + size));
    }
    return rows;
  };
  
  // Slice the articles based on the displayCount
  const displayedArticles = articles.slice(0, displayCount);

  // Chunk the displayed articles into rows
  const articleRows = groupArticlesIntoRows(displayedArticles, 3);
  
  // Function to handle the "See More" button click
  const handleSeeMore = () => {
    setDisplayCount((prevCount) => prevCount + 9); // Increase the count to display 3 more articles
  };

  // Function to render author links
  const renderAuthorLinks = (article) => {
    return article.authors.map((author, index) => (
      <React.Fragment key={index}>
        {index > 0 && (index === article.authors.length - 1 ? " and " : ", ")}
        <Link to={`/author/${author}`} className="author-email-link">
          {author}
        </Link>
      </React.Fragment>
    ));
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading results.</p>;
  }

  if (!articles || articles.length === 0) {
    // Render a message when no results are found, but only if articles are loaded
    return (
      <div className="search-results">
        {!loading && <h1>No results found for "{query}"</h1>}
      </div>
    );
  }

  return (
    <div className="search-results">
      <h1>Search Results for "{query}" </h1>
      {articleRows.map((row, index) => (
        <div className="articles-row-search" key={index}>
          {row.map((article) => (
            <div className="article-card-search" key={article.id}>
              <div className="search-image-container">
              <img
                  src={article.imageUrls[0] || Logo}
                  alt={article.title}
                  className="article-image-search"
                />
              </div>
              <div className="article-contents">
                <h2>
                  <Link
                    to={`/article/${article.category}/${article.id}`}
                    className="title-link-article"
                  >
                    {article.title}
                  </Link>
                </h2>
                <p>
                    By {renderAuthorLinks(article)}&nbsp;&nbsp;
                    <span className="author-email">
                     | &nbsp;{article.email}                  
                    </span>
                  </p>
                  <p>
                    <span className="article-date">
                      {article.createdAt ? formatDate(article.createdAt) : 'Date unavailable'}
                    </span>
                  </p>
                  <div className="description">
                    <p>{article.description}</p>
                  </div>
              </div>
            </div>
          ))}
        </div>
      ))}
      {/* See More Button */}
      {articles && articles.length >= displayCount &&(
        <div className="see-more-container">
          <button onClick={handleSeeMore} className="see-more-button">See More</button>
        </div>
      )}
    </div>
  );
}

export default SearchResultsPage;
