import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../config/firebase-config';


export const fetchArticlesFromCategory = async (category, articlesLimit) => {
  try {
    const articlesCollection = collection(db, `${category}-articles`);
    let articlesQuery;
    
    articlesQuery = query(articlesCollection, orderBy("createdAt", "desc"), limit(articlesLimit));

    const articlesSnapshot = await getDocs(articlesQuery);

    return articlesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching articles: ", error);
    return [];
  }
};


