import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import useArticlesByAuthor from "../../hooks/useFecthArticlesByAuthor";
import formatDate from "../../hooks/useGetDateFormat";
import Logo from '../../images/logo.PNG';
import './styles.css';

const AuthorArticlesPage = () => {

  const [displayCount, setDisplayCount] = useState(9); 

  const { authorName } = useParams();
  const { articles, loading, error } = useArticlesByAuthor(authorName);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (articles.length === 0) {
    return (
      <div className="no-articles-message">
        <p className="first-message-no-articles">No articles found for this writer. Please double check the spelling of the writer's name. Please include the writer's first and last name.</p>
        <p className="second-message-no-articles">You can also look for a specific writer by clicking the button below to browse the list of all previous writers.</p>
        <div className="authors-list-link">
          <Link to="/writers-list">View All Writers</Link>
        </div>
      </div>
    );
  }
  const handleSeeMore = () => {
    setDisplayCount((prevCount) => prevCount + 9);
  };

  const groupArticlesIntoRows = (articles, size) => {
    const row = [];
    for (let i = 0; i < articles.length; i += size) {
      row.push(articles.slice(i, i + size));
    }
    return row;
  };

  // Apply the same grouping logic as in the Sports page
  const articleRow = groupArticlesIntoRows(articles, 3);
  const displayedRows = articleRow.slice(0, Math.ceil(displayCount / 3));

  if (loading) return <div className="loader">Loading...</div>;
  if (error) return <div className="error">Error loading articles.</div>;

  return (
    <div className="author-articles">
      <h1>Articles by {authorName}</h1>

      {displayedRows.map((row, index) => (
        <React.Fragment key={index}>
          <div className="articles-row">
            {row.map((article) => (
              <div className="article-card-authorPage" key={article.id}>
                <div className="image-container-authorPage">
                  <img
                    src={article.imageUrls[0] || Logo}                  
                    alt= {Logo}
                    className="article-image-author"
                  />
                </div>
                <div className="article-contents">
                  <h2>
                    <Link
                      to={`/article/${article.category}/${article.id}`}
                      className="title-link-article"
                    >
                      {article.title}
                    </Link>
                  </h2>
                  <p>
                    <span className="article-date">
                      {article.createdAt
                        ? formatDate(article.createdAt)
                        : "Date unavailable"}
                    </span>
                  </p>
                  <div className="description">
                    <p>{article.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {index < displayedRows.length - 1 && <hr />}
        </React.Fragment>
      ))}

      {articles && articles.length > displayCount && (
        <div className="see-more-container">
          <button onClick={handleSeeMore} className="see-more-button">
            See More
          </button>
        </div>
      )}
    </div>
  );
};

export default AuthorArticlesPage;