import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../config/firebase-config";

export const useDeleteStaff = () => {
  
  const handleDeleteStaff = async (id) => {
    try {
      const staffRef = doc(db, "staff", id);
      await deleteDoc(staffRef);
    } catch (error) {
      console.error("Error deleting staff: ", error);
    }
  };
  
  return { handleDeleteStaff };
};
