import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../config/firebase-config";

export const useFetchParagraphs = (page) => {
  const [paragraphs, setParagraphs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const paragraphsCollection = collection(db, page); 
        const paragraphsQuery = query(paragraphsCollection, orderBy("order"));
        const paragraphsSnapshot = await getDocs(paragraphsQuery);

        const paragraphsData = paragraphsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setParagraphs(paragraphsData);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      } 
    };

    fetchParagraphs();
  }, [page]);

  const updateParagraph = async (paragraphId, updatedData) => {
    try {
      await updateDoc(doc(db, page, paragraphId), updatedData);
    } catch (err) {
      console.error("Error updating paragraphs:", err);
    }
  };

  return { paragraphs, loading, error, updateParagraph };
};
