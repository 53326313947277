import React, { useState } from "react";
import { auth } from "../../config/firebase-config";
import { useFetchParagraphs } from "../../hooks/useFetchParagraphs";
import { marked } from "marked";
import "./styles.css";

function Subscriptions() {
  const { paragraphs, updateParagraph } = useFetchParagraphs("subscriptions");
  const currentUser = auth.currentUser;
  const isSignedIn = !!currentUser;
  const [isEditing, setIsEditing] = useState(false);
  const [draftText, setDraftText] = useState({});

  const handleUpdateParagraphs = async (paragraphId, fieldName) => {
    try {
      await updateParagraph(
        paragraphId,
        { [fieldName]: draftText },
        "subscriptions"
      );
    } catch (err) {
      console.error("Error updating paragraph:", err);
    } finally {
      setIsEditing(false);
      setDraftText("");
    }
  };

  return (
    <div className="container-subscriptions">
      <div className="subscriptions-top">
        <h1>Subscriptions</h1>
        {/* ... Handling Loading and Error States ... */}
        {/* Display the first paragraph under Policies */}
        {paragraphs &&
          paragraphs
            .slice(0, 1)
            .map((paragraph) => (
              <ParagraphComponent
                key={paragraph.id}
                paragraph={paragraph}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                draftText={draftText}
                setDraftText={setDraftText}
                handleUpdateParagraphs={handleUpdateParagraphs}
                isSignedIn={isSignedIn}
              />
            ))}
      </div>

      <div className="subscriptions-list">
        {/* ... Handling Loading and Error States ... */}

        {/* Display the rest of the paragraphs as bullet points */}
        <ul>
          {paragraphs &&
            paragraphs
              .slice(1, 10)
              .map((paragraph) => (
                <ListItemComponent
                  key={paragraph.id}
                  listItem={paragraph}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  draftText={draftText}
                  setDraftText={setDraftText}
                  handleUpdateParagraphs={handleUpdateParagraphs}
                  isSignedIn={isSignedIn}
                />
              ))}
        </ul>
      </div>

      <div className="subscriptions-bottom">
        {/* ... Handling Loading and Error States ... */}

        {/* Display the rest of the paragraphs under Terms of Use */}
        {paragraphs &&
          paragraphs
            .slice(10, 11)
            .map((paragraph) => (
              <ParagraphComponent
                key={paragraph.id}
                paragraph={paragraph}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                draftText={draftText}
                setDraftText={setDraftText}
                handleUpdateParagraphs={handleUpdateParagraphs}
                isSignedIn={isSignedIn}
              />
            ))}
      </div>
      <div className="address">
        {paragraphs &&
          paragraphs
            .slice(11, 16)
            .map((paragraph) => (
              <ParagraphComponent
                key={paragraph.id}
                paragraph={paragraph}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                draftText={draftText}
                setDraftText={setDraftText}
                handleUpdateParagraphs={handleUpdateParagraphs}
                isSignedIn={isSignedIn}
              />
            ))}
      </div>

      {/* <div className="form">
        {paragraphs &&
          paragraphs
            .slice(16)
            .map((paragraph) => (
              <ParagraphComponent
                key={paragraph.id}
                paragraph={paragraph}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                draftText={draftText}
                setDraftText={setDraftText}
                handleUpdateParagraphs={handleUpdateParagraphs}
                isSignedIn={isSignedIn}
              />
            ))}
      </div>

      {/* Add the form below the paragraphs */}
      {/* <form className="contact-form">
        <input type="text" placeholder="Name" />
        <input type="email" placeholder="Email Address" />
        <textarea placeholder="Message"></textarea> */}
        {/* <div className="captcha">
          <input type="text" placeholder="10 + 14 = ?" />
        </div> */}
        {/* <button type="submit">Submit</button>
      </form> */} 
    </div>
  );
}

// Create a new component to avoid duplication
function ParagraphComponent({
  paragraph,
  isEditing,
  setIsEditing,
  draftText,
  setDraftText,
  handleUpdateParagraphs,
  isSignedIn,
}) {
  const createMarkup = (markdownText) => {
    return { __html: marked(markdownText) };
  };
  return (
    <React.Fragment key={paragraph.id}>
      {isEditing === paragraph.id ? (
        <textarea
          value={draftText}
          onChange={(e) => setDraftText(e.target.value)}
        />
      ) : (
        <p dangerouslySetInnerHTML={createMarkup(paragraph.text)} />
      )}
      {isEditing === paragraph.id ? (
        <button onClick={() => handleUpdateParagraphs(paragraph.id, "text")}>
          Submit
        </button>
      ) : (
        isSignedIn && (
          <button
            onClick={() => {
              setIsEditing(paragraph.id);
              setDraftText(paragraph.text);
            }}
          >
            Edit
          </button>
        )
      )}
    </React.Fragment>
  );
}

function ListItemComponent({
  listItem,
  isEditing,
  setIsEditing,
  draftText,
  setDraftText,
  handleUpdateParagraphs,
  isSignedIn,
}) {
  return (
    <li key={listItem.id}>
      {isEditing === listItem.id ? (
        <textarea
          value={draftText}
          onChange={(e) => setDraftText(e.target.value)}
        />
      ) : (
        listItem.text
      )}
      {isEditing === listItem.id ? (
        <button onClick={() => handleUpdateParagraphs(listItem.id, "text")}>
          Submit
        </button>
      ) : (
        isSignedIn && (
          <button
            onClick={() => {
              setIsEditing(listItem.id);
              setDraftText(listItem.text);
            }}
          >
            Edit
          </button>
        )
      )}
    </li>
  );
}

export default Subscriptions;
