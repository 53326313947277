import React, { useState } from "react";
import { auth } from "../../config/firebase-config";
import StaffPerson from "../../components/StaffPerson/Index";
import { useAddStaff } from "../../hooks/useManageStaff";
import { useDeleteStaff } from "../../hooks/useDeleteStaff";
import { useFetchParagraphs } from "../../hooks/useFetchParagraphs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactMarkdown from 'react-markdown';
import { Link } from "react-router-dom";
import "./styles.css";
//  import { getDocs, collection, setDoc, updateDoc, query } from 'firebase/firestore';
//  import { db } from '../../config/firebase-config'; // Adjust the path as per your project structure
//  import { doc } from 'firebase/firestore';

function About() {
  const [showForm, setShowForm] = useState(false);
  const { staffList, newStaff, setNewStaff, handleAddStaff, handleOnDragEnd } =
    useAddStaff();
  const { handleDeleteStaff } = useDeleteStaff();
  const { paragraphs, loading, error, updateParagraph } = useFetchParagraphs("who-we-are");
  const currentUser = auth.currentUser;
  const isSignedIn = !!currentUser;

  const [isEditing, setIsEditing] = useState(false);
  const [draftText, setDraftText] = useState({});

  const handleUpdateParagraphs = async (paragraphId, fieldName) => {
    try {

      await updateParagraph(paragraphId, { [fieldName]: draftText }, "who-we-are");
    } catch (err) {
      console.error("Error updating paragraph:", err);
    } finally {
      setIsEditing(false);
      setDraftText('');
    }
  };

// populates unique author array

  // const populateUniqueAuthors = async () => {
  //   try {
  //     const collections = ['sports-articles', 'news-articles', 'variety-articles', 'opinion-articles'];
  //     let authorsSet = new Set();
  
  //     for (const collectionName of collections) {
  //       console.log(`Fetching from collection: ${collectionName}`);
  //       const querySnapshot = await getDocs(collection(db, collectionName));
  //       querySnapshot.forEach((docSnap) => {
  //         const authorsArray = docSnap.data().authors;
  //         if (authorsArray && Array.isArray(authorsArray)) {
  //           authorsArray.forEach(author => {
  //             if (author) {
  //               authorsSet.add(author);
  //               console.log(`Added author: ${author}`);
  //             }
  //           });
  //         } else {
  //           console.log(`No authors array found in document ID: ${docSnap.id}`);
  //         }
  //       });
  //     }
  
  //     const uniqueAuthors = [...authorsSet];
  //     console.log(`Unique authors: ${uniqueAuthors}`);
  
  //     if (uniqueAuthors.length > 0) {
  //       const authorsDocRef = doc(db, 'unique-authors', 'tNxb2ODPMiYTS9tpnuQy'); // Replace with your document ID
  //       await setDoc(authorsDocRef, { authors: uniqueAuthors });
  //       console.log("Unique authors updated in Firestore");
  //     } else {
  //       console.log("No authors to update");
  //     }
  //   } catch (err) {
  //     console.error("Error populating unique authors:", err);
  //   }
  // };
  
  // const populateContentLowercase = async () => {
  //   try {
  //     const collections = ['sports-articles', 'news-articles', 'variety-articles', 'opinion-articles'];
  
  //     for (const collectionName of collections) {
  //       const articlesQuery = query(collection(db, collectionName));
  //       const articlesSnapshot = await getDocs(articlesQuery);
  
  //       articlesSnapshot.forEach(async (articleDoc) => {
  //         const content = articleDoc.data().content;
  //         const lowercaseContent = content.toLowerCase();
  
  //         const articleRef = doc(db, collectionName, articleDoc.id);
  //         await updateDoc(articleRef, { "content-lowercase": lowercaseContent });
  //       });
  //     }
      
  //     console.log("Content-lowercase field populated for all articles");
  //   } catch (err) {
  //     console.error("Error populating content-lowercase field:", err);
  //   }
  // };
  
  

  return (
    <div className="wrapper">
    <div className="container-about">
      <div className="whoWeAre">
        <h1>Who We Are</h1>
        {/* Add button for populating unique authors */}
        {/* {isSignedIn && (
        <div className="populate-authors">
          <button onClick={populateUniqueAuthors}>
            Populate Unique Authors
          </button>
        </div> */}
        {/* Button to trigger population of text-lowercase field */}
        {/* <button onClick={populateContentLowercase}>Populate content-lowercase field</button> */}

        {/* Rest of your component */}

        {/* Handling Loading and Error States */}
        {loading && <p>Loading...</p>}
        {error && <p>Error loading paragraphs: {error.message}</p>}

        {/* Mapping through fetched paragraphs */}
        {paragraphs &&
          paragraphs.map((paragraph) => (
            <React.Fragment key={paragraph.id}>
              {isEditing === paragraph.id ? (
                <textarea
                  value={draftText}
                  onChange={(e) => setDraftText(e.target.value)}
                />
              ) : (
                <ReactMarkdown>{paragraph.text}</ReactMarkdown>
                // Handling both possible keys
              )}
              {isEditing === paragraph.id ? (
                <button onClick={() => handleUpdateParagraphs(paragraph.id, "text")}>
                  Submit
                </button>
              ) : (
                isSignedIn && (
                  <button
                    onClick={() => {
                      setIsEditing(paragraph.id);
                      setDraftText(paragraph.text);
                      // Handling both possible keys for draftText
                    }}
                  >
                    Edit
                  </button>
                )
              )}
            </React.Fragment>
          ))}
      </div>
      <div className="authors-list-link1">
        <Link to="/writers-list">View All Writers</Link>
      </div>
      <div className="staff">
        <div className="staff-title-top">
          <h1>Our Staff</h1>
        </div>
        {isSignedIn && (
          <>
            <button onClick={() => setShowForm(true)}>Add New Staff</button>
            <p className="tooltip">
              Drag and drop the staff members to rearrange.
            </p>
          </>
        )}
        {showForm && (
          <div>
            <input
              type="text"
              placeholder="Name"
              value={newStaff.name}
              onChange={(e) =>
                setNewStaff((prev) => ({ ...prev, name: e.target.value }))
              }
            />
            <input
              type="text"
              placeholder="Title"
              value={newStaff.title}
              onChange={(e) =>
                setNewStaff((prev) => ({ ...prev, title: e.target.value }))
              }
            />
            <label htmlFor="staffImage">Image: </label>
            <input
              type="file"
              onChange={(e) =>
                setNewStaff((prev) => ({ ...prev, imageFile: e.target.files[0] }))
              }
            />
            <textarea
              placeholder="Bio"
              value={newStaff.bio}
              onChange={(e) =>
                setNewStaff((prev) => ({ ...prev, bio: e.target.value }))
              }
            />
            <ReactMarkdown>{newStaff.bio}</ReactMarkdown>
            <button onClick={handleAddStaff}>Submit</button>
          </div>
        )}
        <div className="staffList">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="staff">
              {(provided) => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="staff-ul"
                >
                  {staffList && staffList.length > 0 ? (
                    staffList.map((staffPerson, index) => (
                      <Draggable
                        key={staffPerson.id}
                        draggableId={staffPerson.id}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...(isSignedIn ? provided.dragHandleProps : {})}
                            className={`draggable-item ${
                              isSignedIn ? "draggable" : "not-draggable"
                            }`}
                          >
                            <StaffPerson
                              key={staffPerson.id} // Key is not needed here as it's already defined in Draggable
                              user={{ ...staffPerson, image: staffPerson.imageURL }}
                              isSignedIn={isSignedIn}
                              onDelete={() => {
                                if (window.confirm('Are you sure you want to delete this staff member?')) {
                                  handleDeleteStaff(staffPerson.id).then(() => {
                                    window.location.reload();
                                  }).catch(error => {
                                    console.error("Error deleting staff member:", error);
                                    alert("Error deleting staff member. Please try again.");
                                  });
                                }
                              }}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <p>No staff available.</p>
                  )}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
    </div>
  );
}

export default About;
