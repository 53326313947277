import React from "react";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import "./styles.css";

function NavbarTop() {
  return (
    <div className="navbar-top">
      <div className="leftSide">
        <div className="phone">
        <b><p>
            <PhoneIcon fontSize="small" /> Newsroom: 320-363-2540
          </p></b>
        </div>
        <div className="email">
        <b><p>
            <EmailIcon fontSize="small" />
            <a href='mailto:record@csbsju.edu' className='email-link'>record@csbsju.edu</a>
          </p></b>
        </div>
      </div>
      <div className="rightside">
      <b><a href="https://cdm.csbsju.edu/digital/collection/CSBArchNews/search" className="print-edition" target="_blank" rel="noopener noreferrer">
            Read the Print Edition
        </a></b>
        
        <b><Link to="/subscriptions" className='subscription-link'>Subscriptions</Link></b>
        {/* <Link to="/contact" className='contact-link'>Contact Us</Link> */}
        <b><Link to="/advertising" className='advertising-link'>Advertising</Link></b>
        <b><Link to="/auth" className='auth-link'>Admin Login</Link></b>
      </div>
    </div>
  );
}

export default NavbarTop;
