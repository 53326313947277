import React, { useState } from "react";
import { auth } from "../../config/firebase-config";
import { useFetchParagraphs } from "../../hooks/useFetchParagraphs";
import { marked } from "marked";
import "./styles.css";

function Contact() {
  const { paragraphs, updateParagraph } = useFetchParagraphs("contact-us");
  const currentUser = auth.currentUser;
  const isSignedIn = !!currentUser;
  const [isEditing, setIsEditing] = useState(false);
  const [draftText, setDraftText] = useState({});

  const handleUpdateParagraphs = async (paragraphId, fieldName) => {
    try {
      await updateParagraph(
        paragraphId,
        { [fieldName]: draftText },
        "contact-us"
      );
    } catch (err) {
      console.error("Error updating paragraph:", err);
    } finally {
      setIsEditing(false);
      setDraftText("");
    }
  };

  return (
    <div className="container-contact">
      <div className="contact-top">
        <h1>Contact Us</h1>
        {/* Assuming paragraphs is an array, display each paragraph here */}
        {paragraphs.map((paragraph) => (
          <ParagraphComponent
            key={paragraph.id}
            paragraph={paragraph}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            draftText={draftText}
            setDraftText={setDraftText}
            handleUpdateParagraphs={handleUpdateParagraphs}
            isSignedIn={isSignedIn}
          />
        ))}
      </div>

      {/* Add the form below the paragraphs */}
      {/* <form className="contact-form">
        <input type="text" placeholder="Name" />
        <input type="email" placeholder="Email Address" />
        <textarea placeholder="Message"></textarea>
        
        <button type="submit">Submit</button>
      </form> */}

    </div>
  );
}

// Create a new component to avoid duplication
function ParagraphComponent({
  paragraph,
  isEditing,
  setIsEditing,
  draftText,
  setDraftText,
  handleUpdateParagraphs,
  isSignedIn,
}) {
  const createMarkup = (markdownText) => {
    return { __html: marked(markdownText) };
  };
  return (
    <React.Fragment key={paragraph.id}>
      {isEditing === paragraph.id ? (
        <textarea
          value={draftText}
          onChange={(e) => setDraftText(e.target.value)}
        />
      ) : (
        <p dangerouslySetInnerHTML={createMarkup(paragraph.text)} />
      )}
      {isEditing === paragraph.id ? (
        <button onClick={() => handleUpdateParagraphs(paragraph.id, "text")}>
          Submit
        </button>
      ) : (
        isSignedIn && (
          <button
            onClick={() => {
              setIsEditing(paragraph.id);
              setDraftText(paragraph.text);
            }}
          >
            Edit
          </button>
        )
      )}
    </React.Fragment>
  );
}



export default Contact;
