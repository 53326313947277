import React from 'react';
import useFetchUniqueAuthors from '../../hooks/useFetchUniqueAuthors';
import { Link } from "react-router-dom";
import "./styles.css";

const AuthorsPage = () => {
  const { authors, loading, error } = useFetchUniqueAuthors();

  if (loading) return <p>Loading authors...</p>;
  if (error) return <p>Error fetching authors.</p>;

  // Sort authors alphabetically
  const sortedAuthors = authors.sort();

  return (
    <div className="authors-page">
      <h1>Writers</h1>
      <ul className="authors-list">
        {sortedAuthors.map((author, index) => (
         <li key={index}>
            <Link to={`/author/${encodeURIComponent(author)}`}>
            {author}
            </Link>
        </li>
        ))}
      </ul>
    </div>
  );
};

export default AuthorsPage;
