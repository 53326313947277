import React, { useState } from "react";
import { db, storage } from "../config/firebase-config";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDoc, doc, setDoc } from 'firebase/firestore';
import imageCompression from "browser-image-compression";

const updateUniqueAuthors = async (newAuthors) => {
  const uniqueAuthorsDocRef = doc(db, 'unique-authors', 'tNxb2ODPMiYTS9tpnuQy'); // Replace with your document ID
  const docSnap = await getDoc(uniqueAuthorsDocRef);

  if (docSnap.exists()) {
    const existingAuthors = docSnap.data().authors || [];
    const updatedAuthors = Array.from(new Set([...existingAuthors, ...newAuthors]));
    await setDoc(uniqueAuthorsDocRef, { authors: updatedAuthors });
  } else {
    console.log("Unique authors document does not exist!");
    // Optionally, create the document if it doesn't exist
  }
};


function ArticleInput() {
  const [title, setTitle] = useState("");
  const [authors, setAuthors] = useState(["", "", "", "", ""]); 
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [caption, setCaption] = useState(""); // State for the caption
  const [category, setCategory] = useState("sports-articles");
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [date, setDate] = useState("");
  const fileInputRef = React.useRef(null);

  const handleAuthorChange = (index, value) => {
    const newAuthors = [...authors];
    newAuthors[index] = value;
    setAuthors(newAuthors);
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 1, // Maximum size of the compressed image (adjust as needed)
      maxWidthOrHeight: 1920, // Maximum width or height of the compressed image
      useWebWorker: true, // Use Web Worker for faster compression
      maxWidth: 1920, // Maximum width of the compressed image
      maxHeight: 1080, // Maximum height of the compressed image
    };
  
    try {
      const compressedImage = await imageCompression(imageFile, options);
      return compressedImage;
    } catch (error) {
      console.error("Error compressing image: ", error);
      return imageFile; // Return original image if compression fails
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const optimizedImages = await Promise.all(
        [image, image2, image3, image4].map(async (imageFile) => {
          if (imageFile) {
            return await compressImage(imageFile);
          }
          return null;
        })
      );
  
      // Upload optimized images to Firebase Storage
      const imageUrls = await Promise.all(
        optimizedImages.map(async (image) => {
          if (image) {
            const storageRef = ref(storage, `article_images/${image.name}`);
            await uploadBytes(storageRef, image);
            return getDownloadURL(storageRef);
          }
          return null;
        })
      );

      

      let createdAt;
      if (date) {
        const parts = date.split('-');
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed
        const day = parseInt(parts[2], 10);

        // Create a UTC date at midnight
        createdAt = new Date(Date.UTC(year, month, day));

        // Add one day to the date
        createdAt.setDate(createdAt.getDate() + 1);
      } else {
        createdAt = new Date();
      }

      const fieldCategory = category.split("-")[0];

      const filteredAuthors = authors.filter(author => author.trim() !== "");

      await addDoc(collection(db, category), {
        title,
        'title-lowercase': title.toLowerCase(), // Adding the lowercase version of the title
        authors: filteredAuthors,
        email,
        description,
        content,
        'content-lowercase': content.toLowerCase(),
        category: fieldCategory,
        createdAt,
        imageUrls: imageUrls.filter((url) => url !== null), // Filter out null values
        caption,
      });

      // Update the unique-authors collection
      await updateUniqueAuthors(filteredAuthors);

      // Reset the form
      setTitle("");
      setAuthors(["", "", "", "", ""]);
      setDescription("");
      setContent("");
      setImage(null);
      setImage2(null);
      setImage3(null);
      setImage4(null);
      setCaption("");
      setDate("");

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <select value={category} onChange={(e) => setCategory(e.target.value)}>
        <option value="sports-articles">Sports</option>
        <option value="opinion-articles">Opinion</option>
        <option value="news-articles">News</option>
        <option value="variety-articles">Variety</option>
      </select>
      <input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
        required
      />
      {authors.map((author, index) => (
        <input
          key={index}
          value={author}
          onChange={(e) => handleAuthorChange(index, e.target.value)}
          placeholder={`Author ${index + 1}`}
          required={index === 0} // Make the first author field required
        />
      ))}
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <input
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
        required
      />
      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => setImage(e.target.files[0])}
        accept="image/jpeg, image/jpg,image/png, image/gif, image/bmp"
      />
      <input
        type="file"
        onChange={(e) => setImage2(e.target.files[0])}
        accept="image/jpeg, image/jpg,image/png, image/gif, image/bmp"
      />
      <input
        type="file"
        onChange={(e) => setImage3(e.target.files[0])}
        accept="image/jpeg, image/jpg,image/png, image/gif, image/bmp"
      />
      <input
        type="file"
        onChange={(e) => setImage4(e.target.files[0])}
        accept="image/jpeg, image/jpg,image/png, image/gif, image/bmp"
      />
      <input
        value={caption}
        onChange={(e) => setCaption(e.target.value)}
        placeholder="Image Caption"
      />
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        placeholder="Creation Date"
      />
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Content"
        required
      />
      <button type="submit">Submit</button>
    </form>
  );
}

export default ArticleInput;
