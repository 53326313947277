import { useState, useEffect } from 'react';
import { db } from '../config/firebase-config';
import { doc, getDoc } from 'firebase/firestore';

function useFetchFeaturedArticle3() {
  const [featuredArticle3, setFeaturedArticle] = useState(null);
  const [loading3, setLoading] = useState(true);
  const [error3, setError] = useState(null);

  useEffect(() => {
    async function fetchFeaturedArticle() {
      try {
        // Gets the document ID and collection name of the featured article.
        const featuredDocRef = doc(db, 'featured-articles', 'featured-article3');
        const featuredDocSnap = await getDoc(featuredDocRef);

        if (featuredDocSnap.exists()) {
          const { articleID, collectionName } = featuredDocSnap.data();
          // Fetch the featured article using the retrieved document ID.
          const articleRef = doc(db, collectionName, articleID);
            const articleSnap = await getDoc(articleRef);

            if (articleSnap.exists()) {
                const articleData = {
                    ...articleSnap.data(),
                    id: articleSnap.id,  // Add the document ID
                    collectionName  // Add the collection name
                };
                setFeaturedArticle(articleData);
            }
        }

      } catch (err) {
        console.error("Error fetching featured article:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchFeaturedArticle();
  }, []);

  return { featuredArticle3, loading3, error3 };
}

export default useFetchFeaturedArticle3;
