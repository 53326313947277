import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  updateDoc,
  doc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../config/firebase-config";

export const useAddStaff = () => {
  const [staffList, setStaffList] = useState([]);
  const [newStaff, setNewStaff] = useState({
    name: "",
    title: "",
    bio: "",
    order: 0,
    imageFile: null,
    imageURL: ""
  });

  useEffect(() => {
    const fetchStaff = async () => {
      const staffCollection = collection(db, "staff");
      const staffSnapshot = await getDocs(
        query(staffCollection, orderBy("order"))
      );
      const staffList = staffSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStaffList(staffList);
    };
    fetchStaff();
  }, []);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedStaffList  = Array.from(staffList);
    const [reorderedItem] = reorderedStaffList.splice(result.source.index, 1);
    reorderedStaffList.splice(result.destination.index, 0, reorderedItem);

    setStaffList(reorderedStaffList ); // Updating local state

    // Updating the order in Firestore for each staff member
    for (const [index, staff] of reorderedStaffList.entries()) {
      try {
        await updateDoc(doc(db, "staff", staff.id), {
          order: index,
        });
      } catch (error) {
        console.error("Error updating staff order: ", error);
      }
    }
  };

  const handleAddStaff = async () => {
    try {
      let imageURL = "";
      if (newStaff.imageFile) {
        // Define a storage reference.
        const storageRef = ref(storage, `staff_images/${newStaff.imageFile.name}`);
        // Start the upload.
        await uploadBytes(storageRef, newStaff.imageFile);

        imageURL = await getDownloadURL(storageRef);
      }
      
      const newOrder = staffList.length;
      // Add the staff with the image URL.
      await addDoc(collection(db, "staff"), {
        name: newStaff.name,
        title: newStaff.title,
        bio: newStaff.bio,
        order: newOrder,
        imageURL: imageURL, // Only set the URL in Firestore
      });
      // Reset the form.
      setNewStaff({
        name: "",
        title: "",
        bio: "",
        order: 0,
        imageFile: null,
      });
    } catch (error) {
      console.error("Error adding new staff: ", error);
    }
  };

  return { staffList, newStaff, setNewStaff, handleAddStaff, handleOnDragEnd };
};
