// recomputeUniqueAuthors.js
import { getDocs, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config'; // Adjust the path as needed

export const recomputeUniqueAuthors = async () => {
  try {
    const collections = ['sports-articles', 'news-articles', 'variety-articles', 'opinion-articles'];
    let authorsSet = new Set();

    for (const collectionName of collections) {
      const querySnapshot = await getDocs(collection(db, collectionName));
      querySnapshot.forEach((docSnap) => {
        const authorsArray = docSnap.data().authors;
        if (Array.isArray(authorsArray)) {
          authorsArray.forEach(author => {
            if (author) {
              authorsSet.add(author);
            }
          });
        }
      });
    }

    const uniqueAuthors = [...authorsSet];
    const authorsDocRef = doc(db, 'unique-authors', 'tNxb2ODPMiYTS9tpnuQy'); // Replace with your document ID
    await setDoc(authorsDocRef, { authors: uniqueAuthors });
  } catch (err) {
    console.error("Error recomputing unique authors:", err);
  }
};
