import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../config/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import formatDate from "../../hooks/useGetDateFormat";
import ReactMarkdown from "react-markdown";
import "./styles.css";

const ArticlePage = () => {
  const { category, id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchArticle = async () => {
      try {
        const docRef = doc(db, `${category}-articles`, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists) {
          setArticle(docSnap.data());
        } else {
          setError("Article not found");
        }
      } catch (err) {
        console.error("Error fetching article: ", err);
        setError("Error fetching article");
      } finally {
        setLoading(false);
      }
    };
    fetchArticle();
  }, [category, id]);

  const renderAuthorLinks = (article) => {
    if (Array.isArray(article.authors)) {
      // Filter out empty strings
      const filteredAuthors = article.authors.filter(author => author.trim() !== "");
  
      return filteredAuthors.map((author, index) => (
        <React.Fragment key={index}>
          {index > 0 && (index === filteredAuthors.length - 1 ? ' and ' : ', ')}
          <Link to={`/author/${author}`} className="author-email-link">
            {author}
          </Link>
        </React.Fragment>
      ));
    } else {
      // For older articles with a single author
      return (
        <Link to={`/author/${article.author}`} className="author-email-link">
          {article.author}
        </Link>
      );
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!article) return <p>No article found.</p>;
  const hasMultipleImages = article.imageUrls && article.imageUrls.length > 1;

  return (
    <div className="container-article-page">
      <h1>{article.title}</h1>
      <div
        className={`articlePage-images-container ${
          hasMultipleImages ? "multiple-images" : ""
        }`}
      >
        {article.imageUrls?.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`${article.title} - image ${index + 1}`}
            className="article-image-side-by-side"
          />
        ))}
      </div>
      {article.caption && <p className="image-caption">{article.caption}</p>}
      <div className="author-and-date">
        <p>
          By {renderAuthorLinks(article)}&nbsp;
          <span className="author-email-articlePage"></span>
        </p>
        <span>{article.email}</span>
        <p>
          <span className="article-date-articlePage">
            {article.createdAt
              ? formatDate(article.createdAt)
              : "Date unavailable"}
          </span>
        </p>
      </div>
      <div className="article-page-contents">
        <ReactMarkdown>{article.content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default ArticlePage;
