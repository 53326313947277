import "./App.css";
import Home from './pages/Home';
import NavbarTop from './components/NavbarTop/index';
import NavbarBottom from './components/NavbarBottom/index';
import NavbarMiddle from './components/NavbarMiddle/index';
import Footer from './components/Footer/index';
import Sports from './pages/Sports';
import Variety from './pages/Variety';
import News from './pages/News';
import About from './pages/About';
import Opinion from './pages/Opinion/index';
import Auth from './pages/Auth/index';
import Contact from './pages/Contact/index';
import Writers from './pages/Authors/index';
import Subscriptions from './pages/Subscriptions/index';
import Advertising from './pages/Advertising/index';
import Policies from './pages/Policies/index';
import ArticlePage from './pages/ArticlePage/index';
import AuthorArticlesPage from './pages/AuthorArticles/index';
import SearchResultsPage from './pages/SearchResults/index';
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { lazy, Suspense } from "react";

// const NavbarTop = lazy(() => import("./components/NavbarTop/index"));
// const NavbarBottom = lazy(() => import("./components/NavbarBottom/index"));
// const NavbarMiddle = lazy(() => import("./components/NavbarMiddle/index"));
// const Footer = lazy(() => import("./components/Footer/index"));

// const Home = lazy(() => import("./pages/Home/index"));
// const Sports = lazy(() => import("./pages/Sports/index"));
// const Variety = lazy(() => import("./pages/Variety/index"));
// const News = lazy(() => import("./pages/News/index"));
// const About = lazy(() => import("./pages/About/index"));
// const Opinion = lazy(() => import("./pages/Opinion/index"));
// const Auth = lazy(() => import("./pages/Auth/index"));
// const Contact = lazy(() => import("./pages/Contact/index"));
// const Subscriptions = lazy(() => import("./pages/Subscriptions/index"));
// const Advertising = lazy(() => import("./pages/Advertising/index"));
// const Policies = lazy(() => import("./pages/Policies/index"));
// const ArticlePage = lazy(() => import("./pages/ArticlePage/index"));
// const AuthorArticlesPage = lazy(() => import("./pages/AuthorArticles/index"));
// const SearchResultsPage = lazy(() => import("./pages/SearchResults/index"));

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          {/* <Suspense fallback={<h2>Loading...</h2>}> */}
            <NavbarTop />
            <NavbarMiddle />
            <NavbarBottom />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/sports" exact component={Sports} />
              <Route path="/variety" exact component={Variety} />
              <Route path="/news" exact component={News} />
              <Route path="/about" exact component={About} />
              <Route path="/opinion" exact component={Opinion} />
              <Route path="/auth" exact component={Auth} />
              <Route path="/contact" exact component={Contact} />
              <Route path="/subscriptions" exact component={Subscriptions} />
              <Route path="/advertising" exact component={Advertising} />
              <Route path="/policies" exact component={Policies} />
              <Route path="/writers-list" exact component={Writers} />
              <Route
                path="/article/:category/:id"
                exact
                component={ArticlePage}
              />
              <Route
                path="/author/:authorName"
                component={AuthorArticlesPage}
              />
              <Route path="/search-results" component={SearchResultsPage} />
            </Switch>

            <Footer />
          {/* </Suspense> */}
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
