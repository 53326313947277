import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { auth, db } from '../../config/firebase-config'; 
import { doc, deleteDoc } from 'firebase/firestore'; 
import { useFetchArticles } from "../../hooks/useFetchArticles";
import formatDate from "../../hooks/useGetDateFormat";
import { recomputeUniqueAuthors } from '../../utility/recomputeUniqueAuthors';
import "./styles.css";

function Opinion() {
  const [displayCount, setDisplayCount] = useState(9); // Start with displaying 9 articles

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { articles: opinionArticles, loading: opinionLoading, error: opinionError } = useFetchArticles("opinion", displayCount);

  
  // Function to handle the "See More" button click
  const handleSeeMore = () => {
    setDisplayCount((prevCount) => prevCount + 9); // Increase the count to display 3 more articles
  };

  // Function to chunk the articles array into groups of 3
  const groupArticlesIntoRows = (articles, size) => {
    const row = [];
    for (let i = 0; i < articles.length; i += size) {
      row.push(articles.slice(i, i + size));
    }
    return row;
  };

  const renderAuthorLinks = (article) => {
    if (Array.isArray(article.authors)) {
      // Filter out empty strings
      const filteredAuthors = article.authors.filter(author => author.trim() !== "");
  
      return filteredAuthors.map((author, index) => (
        <React.Fragment key={index}>
          {index > 0 && (index === filteredAuthors.length - 1 ? ' and ' : ', ')}
          <Link to={`/author/${author}`} className="author-email-link">
            {author}
          </Link>
        </React.Fragment>
      ));
    } else {
      // For older articles with a single author
      return (
        <Link to={`/author/${article.author}`} className="author-email-link">
          {article.author}
        </Link>
      );
    }
  };

  // Chunk the articles to be displayed
  const articleRow = groupArticlesIntoRows(opinionArticles, 3);

  // Determine the number of rows to display based on displayCount
  const displayedRows = articleRow.slice(0, Math.ceil(displayCount / 3));

  const handleDeleteOpinionArticle = async (articleId) => {
    if (!window.confirm('Are you sure you want to delete this article?')) return;
    try {
      await deleteDoc(doc(db, 'opinion-articles', articleId));
      await recomputeUniqueAuthors(); // Recompute unique authors after deletion

      // Refresh the articles or handle the UI update
    } catch (error) {
      console.error('Error deleting article:', error);
    }
  };

  return (
    <div className="opinion-articles">
      <h1>Opinion</h1>
      {opinionLoading && <p>Loading...</p>}
      {opinionError && <p>Error loading articles.</p>}

      {displayedRows.map((row, index) => (
        <React.Fragment key={index}>
          <div className="articles-row">
            {row.map((article) => (
              <div className="article-card-opinion" key={article.id}>
                <div className="opinion-image-container">
                  <img
                    src={
                      article.imageURL ||
                      null
                    }
                    alt={null}
                    className="article-image-opinion"
                  />
                </div>
                <div className="article-contents">
                  <h2>
                    <Link
                      to={`/article/opinion/${article.id}`}
                      className="title-link"
                    >
                      {article.title}
                    </Link>
                  </h2>
                  <p>
                    By {renderAuthorLinks(article)}&nbsp;&nbsp;
                    <span className="author-email">
                     | &nbsp;{article.email}                  
                    </span>
                  </p>
                  <p>
                    <span className="article-date">
                      {article.createdAt ? formatDate(article.createdAt) : 'Date unavailable'}
                    </span>
                  </p>
                  <div className="description">
                    <p>{article.description}</p>
                  </div>
                </div>
                {auth.currentUser && (
                  <button onClick={() => handleDeleteOpinionArticle(article.id)} className="delete-article-button">
                    Delete Article
                  </button>
                )}
              </div>
            ))}
          </div>
          {index < displayedRows.length - 1 && <hr />}{" "}
          {/* Only add <hr /> between rows, not after the last row */}
        </React.Fragment>
      ))}

      {opinionArticles && opinionArticles.length >= displayCount && (
        <div className="see-more-container">
          <button onClick={handleSeeMore} className="see-more-button">
            See More
          </button>
        </div>
      )}
    </div>
  );
}

export default Opinion;
