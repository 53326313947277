import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import SearchInput from "../../components/SearchInput";
import "./styles.css";

function NavbarBottom() {
  const [showLinks, setShowLinks] = useState(false);

  const toggleLinks = () => setShowLinks(!showLinks);

  // This effect sets up a resize event listener to hide the links when the screen is enlarged
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 600) {
        setShowLinks(false);
      }
    };

    // Add the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="navbar-bottom">
      <div className="menu-icon" onClick={toggleLinks}>
        {/* Toggle between hamburger and close icon */}
        {showLinks ? <FaTimes /> : <FaBars />}
      </div>
      <div className={showLinks ? "links active" : "links"}>
        <Link to="/" onClick={() => setShowLinks(false)}>
          Home
        </Link>
        <Link to="/news" onClick={() => setShowLinks(false)}>
          News
        </Link>
        <Link to="/variety" onClick={() => setShowLinks(false)}>
          Variety
        </Link>
        <Link to="/sports" onClick={() => setShowLinks(false)}>
          Sports
        </Link>
        <Link to="/opinion" onClick={() => setShowLinks(false)}>
          Opinion
        </Link>
        <Link to="/about" onClick={() => setShowLinks(false)}>
          About Us
        </Link>
        <SearchInput />
      </div>
    </div>
  );
}

export default NavbarBottom;
