import { useState, useEffect } from 'react';
import { db } from '../config/firebase-config';
import { query, collection, where, getDocs, orderBy } from 'firebase/firestore';

const useArticlesByAuthor = (authorName) => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);

        const fetchArticlesByAuthor = async () => {
            try {
                const articleCollections = ['sports-articles', 'news-articles', 'opinion-articles', 'variety-articles'];
                const allArticles = [];

                for (let coll of articleCollections) {
                    // Query for the old structure (single author)
                    // const q1 = query(collection(db, coll), where("author", "==", authorName), orderBy("createdAt", "desc"));
                    // const querySnapshot1 = await getDocs(q1);
                    // const articlesData1 = querySnapshot1.docs.map(doc => ({
                    //     id: doc.id,
                    //     ...doc.data()
                    // }));

                    // Query for the new structure (array of authors)
                    const q = query(collection(db, coll), where("authors", "array-contains", authorName), orderBy("createdAt", "desc"));
                    const querySnapshot = await getDocs(q);
                    const articlesData = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    allArticles.push(...articlesData);
                }

                // Sort all articles by date
                allArticles.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

                setArticles(allArticles);
            } catch (err) {
                console.error("Error fetching articles: ", err);
                setError('Error fetching articles');
            } finally {
                setLoading(false);
            }
        };

        fetchArticlesByAuthor();
    }, [authorName]);

    return { articles, loading, error };
};

export default useArticlesByAuthor;
